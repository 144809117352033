<template>
<div class="section">
  <div class="columns is-centered" :class="{ 'px-0 pt-0': isMobile, 'px-6 py-5': isDesktop }">
    <div class="column is-12">
      <Markdown :text="text" />
    </div>
  </div>
</div>
</template>

<script>
import Markdown from '@/components/common/Markdown.vue'
import VueBreakpointMixin from 'vue-breakpoint-mixin'

export default {
  name: 'Terms',
  components: {
    Markdown,
  },
  mixins: [VueBreakpointMixin],
  data() {
    return {
      text: `
## Общи условия

&nbsp; Моля, прочетете този документ внимателно. Той съдържа Общите условия за ползване на услугите, достъпни през уебсайта https://cryptodesk.bg („Общите условия“). Ако не приемате Общите условия, посочени тук, няма възможност да използвате CryptoDesk. Използването на услугите, достъпни без регистрация, ще се счита за израз на съгласие с тези Общите условия.

&nbsp; Във връзка с предоставяните услуги **БЛОКЧЕЙН ТЕХ** обработва лични данни съгласно Политиката за защита на личните данни, с която можете да се запознаете https://cryptodesk.bg/privacy. Политиката за защита на личните данни е неразделна част от тези Общите условия.

&nbsp; Моля да имате предвид, че използването на услугите, достъпни през уебсайта https://cryptodesk.bg, включва сключването на договори от разстояние. Уреденото в приложимото законодателство право на потребителя на отказ в 14-дневен срок от сключването на такъв договор не се прилага по отношение на индивидуалния договор за покупка или продажба на криптоактиви, сключван между БЛОКЧЕЙН ТЕХ и Вас (КЛИЕНТ), тъй като цената на криптоактивите зависи от колебанията на финансовия пазар, които не могат да бъдат контролирани от БЛОКЧЕЙН ТЕХ и които могат да настъпят по време на срока за упражняване правото на отказ.

**ОБЩИ УСЛОВИЯ ЗА ПОЛЗВАНЕ НА УСЛУГИТЕ, ДОСТЪПНИ ПРЕЗ УЕБСАЙТА WWW.CRYPTODESK.BG**

**(Общи условия)
by Blockchain Tech**

>**I. ОБЩИ ПОЛОЖЕНИЯ**

>**1\. ЗА БЛОКЧЕЙН ТЕХ**

&nbsp; „Блокчейн Тех“ ЕООД е еднолично дружество с ограничена отговорност, регистрирано в Търговския регистър и регистъра на ЮЛНЦ към Агенцията по вписванията на Република България под ЕИК 204944198 („БЛОКЧЕЙН ТЕХ“ или „Дружеството“). Дружеството предоставя услуги по обмяна между виртуални валути и признати валути без златно покритие. Дружеството е вписано в [регистъра по чл. 9а от Закона за мерките срещи изпиране на пари](https://nra.bg/wps/portal/nra/registers-i-spisuci/registers/page.virtualni.valuti/registur-na-licata-predstaviashti-uslugi-za-obmqna-megdu-virtualni-i-priznati-valuti) (ЗМИП) като лице, което по занятие предоставя услуги за обмяна между виртуални валути и признати валути без златно покритие с Удостоверение № ВВ-1 от 16.09.2020 г.

>**2\. ОПРЕДЕЛЕНИЯ**

&nbsp; При прилагане и тълкуване на настоящите Общи условия използваните термини и изрази имат следното значение:

1. 2.1. **„БЛОКЧЕЙН ТЕХ“** (нас; ние) е „Блокчейн Тех“ ЕООД – еднолично дружество с ограничена отговорност, регистрирано в Търговския регистър и регистъра на ЮЛНЦ към Агенцията по вписванията на Република България под ЕИК 204944198, със седалище и адрес на управление: гр. София, п.к. 1303, р-н Възраждане, бул. „Александър Стамболийски“ № 96, ет. 1, ап. 29, регистрирано по ЗДДС с ДДС номер BG204944198, тел. […], адрес на електронна поща (email) […];
&nbsp;
1. 2.2. **„CryptoDesk/ УЕБСАЙТ“** е уебсайтът www.cryptodesk.bg, собственост на и администриран от БЛОКЧЕЙН ТЕХ – виртуален информационен ресурс в Интернет за предоставяне на услуги на информационното общество и за продажба на услуги от разстояние, които се доставят след изричното им заявяване от КЛИЕНТ чрез подаване на ПОРЪЧКА, потвърждаването ѝ от страна на БЛОКЧЕЙН ТЕХ и съгласно сключения с БЛОКЧЕЙН ТЕХ ИНДИВИДУАЛЕН ДОГОВОР;
&nbsp;
1. 2.3. **„КЛИЕНТ“** (Вас/Вие)-е дееспособно физическо лице (навършило 18 години и непоставено под запрещение), което заявява и/или използва услуги на БЛОКЧЕЙН ТЕХ и има АКАУНТ в CryptoDesk;
&nbsp;
1. 2.4. **„КРИПТОАКТИВ“ / „ВИРТУАЛНА ВАЛУТА“** означава цифрово представяне на стойност или права, които могат да бъдат обменяни, прехвърляни и съхранявани електронно посредством технология на разпределения регистър или друга подобна технология, и представлява виртуална валута по смисъла на Закона за мерките срещу изпирането на пари; за избягване на съмнение, в текста на настоящите Общи условия двата термина се използват като взаимнозаменяеми;
&nbsp;
1. 2.5. **„РАМКОВ ДОГОВОР ЗА ИЗПОЛЗВАНЕ НА УСЛУГИТЕ НА CryptoDesk / РАМКОВ ДОГОВОР“** е настоящият договор за използване на услугите, които БЛОКЧЕЙН ТЕХ предоставя чрез CryptoDesk, който се сключва при тези Общи условия между БЛОКЧЕЙН ТЕХ и Вас като КЛИЕНТ на БЛОКЧЕЙН ТЕХ при регистрацията на АКАУНТ в CryptoDesk и неговото активиране;
&nbsp;
1. 2.6. **„ИНДИВИДУАЛЕН ДОГОВОР“** е договорът за покупка или продажба на КРИПТОАКТИВИ, който се сключва между БЛОКЧЕЙН ТЕХ и Вас като КЛИЕНТ на БЛОКЧЕЙН ТЕХ при получаване на съобщението на БЛОКЧЕЙН ТЕХ за потвърждаване на направената от Вас ПОРЪЧКА;
&nbsp;
1. 2.7. **„KYC ПРОВЕРКА“ („KNOW YOUR CUSTOMER ПРОВЕРКА“) / „ВЕРИФИКАЦИЯ“** е комплексна проверка на КЛИЕНТИТЕ на БЛОКЧЕЙН ТЕХ, включваща използването на специализирани софтуери за идентифициране на КЛИЕНТИТЕ и идентифициране на свързаните с тях и с ОПЕРАЦИИТЕ рискове и състояща се включително, но не само от анализ на документи, изображения и други входни данни, подадени от Вас с цел проверка на Вашата самоличност и други относими обстоятелства и при спазване на изискванията на законодателството срещу изпиране на пари и финансиране на тероризма;
&nbsp;
1. 2.8. **„ЗЛОУМИШЛЕНИ ДЕЙСТВИЯ“** са действия или бездействия, нарушаващи интернет етиката или нанасящи вреди на лица, свързани към интернет или асоциирани мрежи, изпращане на нежелани съобщения (непоискани търговски съобщения, SPAM, JUNK MAIL), препълване на каналите (FLOOD),
получаване на достъп до ресурси с чужди права и пароли, използване на недостатъци в информационните системи с цел собствена облага или добиване на информация (HACK), промяна на самоличността, извършване на действия, които могат да бъдат квалифицирани като промишлен шпионаж или саботаж, повреждане или разрушаване на информационни системи или информационни масиви (CRACK), изпращане на „троянски коне“ или предизвикване инсталация на вируси или системи за отдалечен контрол, смущаване нормалната работа на останалите потребители на интернет и асоциираните мрежи, извършване на каквито и да е било действия, които могат да се квалифицират като престъпление или административно нарушение по българското законодателство или по друго приложимо право;
&nbsp;
1. 2.9. **„НЕПРЕОДОЛИМА СИЛА“** е непредвидено или непредотвратимо събитие от извънреден характер, възникнало след сключването на РАМКОВИЯ ДОГОВОР или на съответен ИНДИВИДУАЛЕН ДОГОВОР между Вас и БЛОКЧЕЙН ТЕХ, което обективно препятства изпълнението по съответния договор, включително, но не само: властническо разпореждане, нормативно изменение, технологичен срив, загуба на данни, токов удар, хакерска атака, бедствие, и други подобни; за избягване на съмнение, изменението на пазарните цени на КРИПТОАКТИВИТЕ не представлява НЕПРЕОДОЛИМА СИЛА;
&nbsp;
1. 2.10. **„ОПЕРАЦИЯ“** е прехвърлянето на определено количество КРИПТОАКТИВИ, платени от Вас, по Вашия ПОРТФЕЙЛ ЗА КРИПТОАКТИВИ, респективно – заплащане на цената на прехвърлените от Вас по ПОРТФЕЙЛА на БЛОКЧЕЙН ТЕХ КРИПТОАКТИВИ съгласно сключен с Вас ИНДИВИДУАЛЕН ДОГОВОР;
&nbsp;
1. 2.11. **„ПАРОЛА“** е избрана от Вас уникална комбинация от букви, цифри и/или знаци, която заедно с посочения от Вас при регистрацията Ви адрес на електронна поща служи за достъп до Вашия АКАУНТ;
&nbsp;
1. 2.12. **„ПОРЪЧКА“** е всяка поръчка за покупка или продажба на определено количество КРИПТОАКТИВИ, заявена от Вас посредством CryptoDesk и отправена към БЛОКЧЕЙН ТЕХ;
&nbsp;
1. 2.13. **„ПОТРЕБИТЕЛСКИ АКАУНТ / АКАУНТ“** е обособена част от CryptoDesk, съдържаща информация за Вас, предоставена от Вас при регистрацията Ви като КЛИЕНТ и при ползване на предоставяните посредством CryptoDesk услуги и съхранявана при БЛОКЧЕЙН ТЕХ;
&nbsp;
1. 2.14. **„ПОРТФЕЙЛ ЗА КРИПТОАКТИВИ“** е устройство, физически носител, програма или услуга, която съхранява публичните и/или частните ключове за транзакции с КРИПТОАКТИВИ, като в допълнение към тази основна функция за съхраняване на ключовете, портфейлът за криптовалута
по-често предлага и функционалност за криптиране и/или подписване на
информация;
&nbsp;
1. 2.15. **„ПРАВА ВЪРХУ ИНТЕЛЕКТУАЛНА СОБСТВЕНОСТ“** означава всички патенти, права върху изобретения, полезни модели, авторско право и сродни права, търговски марки, марки за услуги, търговски, фирмени имена и имена на домейни, права върху дизайн, права върху компютърен софтуер, права върху бази данни, топографски права, морални права, права върху поверителна информация (включително ноу-хау и търговски тайни) и всякакви други права върху интелектуална собственост, регистрирани или нерегистрирани и включително всички заявления за и подновявания или разширявания на такива права, както и всички подобни или еквивалентни права или форми на защита във всяка част на света;

>**3\. ЗА CRYPTODESK И НАШИТЕ УСЛУГИ**

1. 3.1. CryptoDesk е уебсайтът https://cryptodesk.bg – иновативна платформа за търговия с КРИПТОАКТИВИ, на която БЛОКЧЕЙН ТЕХ предоставя услуги за обмяна на КРИПТОАКТИВИ като BTC, ETH, LTC, XRP, BCH и др., срещу заплащане на възнаграждение. По-конкретно, БЛОКЧЕЙН ТЕХ съдейства на своите КЛИЕНТИ да продават своите КРИПТОАКТИВИ за фиатна валута (призната валута) или да купуват нови КРИПТОАКТИВИ с фиатна валута, за което те дължат заплащане на възнаграждение на БЛОКЧЕЙН ТЕХ. Фиатните валути, с които оперира БЛОКЧЕЙТ ТЕХ при предоставяне на своите услуги са български лев (BGN) и евро (EUR). 
&nbsp;
1. 3.2. БЛОКЧЕЙН ТЕХ не съхранява и не държи под каквато и да е форма КРИПТОАКТИВИ от името на своите КЛИЕНТИ и не извършва други услуги, свързани с КРИПТОАКТИВИ, извън посочените по т. 3.1.
&nbsp;
1. 3.3. Част от услугите, предоставяни от БЛОКЧЕЙН ТЕХ чрез CryptoDesk, се предоставят, без да е необходима регистрация. Такива могат да са, без да се ограничават до, услуги, свързани с търсене и получаване на достъп до съдържание, публикувано на CryptoDesk, получаване на информация за цена на избран от посетителя КРИПТОАКТИВ (калкулатор) и други подобни.
&nbsp;
1. 3.4. Част от услугите, предоставяни от БЛОКЧЕЙН ТЕХ чрез CryptoDesk, могат да се използват само след предварителна регистрация, създаване на АКАУНТ и неговото активиране чрез успешно преминаване на KYC ПРОВЕРКА. Тези услуги включват, без да се ограничават до, услугите по обмяна на КРИПТОАКТИВИ по т. 3.1.
&nbsp;
1. 3.5. Услугите по обмяна на КРИПТОАКТИВИ носят висока степен на риск поради:
1. 3.5.1. липсата на цялостна правна регулация на КРИПТОАКТИВИТЕ и услугите, свързани с КРИПТОАКТИВИТЕ, които остават извън обхвата на приложимото законодателство в областта на финансовите услуги, както и факта, че пазарите, на които се търгуват КРИПТОАКТИВИ, са децентрализирани и в голямата си част – също нерегулирани;
1. 3.5.2. динамичните и непредвидими пазарни изменения, обуславящи възможността да загубите напълно или частично своите средства при покупката или продажбата на КРИПТОАКТИВИ, тъй като цената на КРИПТОАКТИВИТЕ зависи от колебанията на финансовите пазари, върху които БЛОКЧЕЙН ТЕХ не може да влияе, и
1. 3.5.3. атрактивността на КРИПТОАКТИВИТЕ като обекти с висок риск за кражба или загуба. Поради изложените в т. 3.5.1-3.5.3 причини е възможно да претърпите големи загуби в резултат на различни рискове като например: закупуването на КРИПТОАКТИВИ, които не са подходящи за Вашите нужди и рисков профил, прекратяване на оперирането на определена борса за КРИПТОАКТИВИ или съществуването на определен КРИПТОАКТИВ, рязка промяна в стойността на определен КРИПТОАКТИВ, хакерски атаки срещу Вашия ПОРТФЕЙЛ ЗА КРИПТОАКТИВИ или загуба на ключа за достъп до него и други рискове.

>**4\. ОБЩИ УСЛОВИЯ**

1. 4.1. Тези Общи условия уреждат отношенията между БЛОКЧЕЙН ТЕХ и всеки един от посетителите и КЛИЕНТИТЕ на уебсайта https://cryptodesk.bg . Текстът на настоящите Общи условия е достъпен в интернет на адрес https://cryptodesk.bg/terms по начин, който позволява тяхното съхраняване и възпроизвеждане.
&nbsp;
1. 4.2. С всяко използване на информационните услуги и ресурси в CryptoDesk се счита, че Вие сте запознати с настоящите Общи условия, съгласявате се с тях и се задължавате да ги спазвате. Ако като посетител на CryptoDesk не сте съгласни с което и да било от изискванията, предвидени в тези Общи условия, следва да не използвате която и да е от услугите, предлагани от БЛОКЧЕЙН ТЕХ на CryptoDesk, и да напуснете незабавно CryptoDesk.
&nbsp;
1. 4.3. Доколкото предоставяните от БЛОКЧЕЙН ТЕХ услуги са постоянно допълвани и модифицирани, включително и във връзка със законодателни промени, настоящите Общи условия могат да бъдат променяни едностранно от БЛОКЧЕЙН ТЕХ.
&nbsp;
1. 4.4. При извършване на промени в Общите условия, БЛОКЧЕЙН ТЕХ довежда тези промени до Вашето знание съобразно изискванията на приложимото законодателство, включително и чрез публикуването им на видно място в CryptoDesk или във Вашия АКАУНТ. Промените в Общите условия не засягат отношенията между Вас като КЛИЕНТ и БЛОКЧЕЙН ТЕХ, възникнали във връзка с валидно подадена преди уведомлението за промяна в Общите условия ПОРЪЧКА.

>**5\. ИЗВЪРШВАНЕ НА ЕЛЕКТРОННИ ИЗЯВЛЕНИЯ. ЕЛЕКТРОННИ ДОКУМЕНТИ И ЕЛЕКТРОННО ПОДПИСВАНЕ**

1. 5.1. При регистрация в CryptoDesk и при последващо извършване на действия по заявяване на различни услуги чрез функционалностите на CryptoDesk и Вашия АКАУНТ, чрез попълване на съответните електронни форми, натискането на виртуални бутони или електронни препратки (линкове) и/или чрез въвеждане на различни кодове за потвърждение, които можете да получите на посочените от Вас при регистрацията Ви електронна поща и/или телефон или в АКАУНТ си, Вие извършвате електронно изявление по смисъла на Закона за електронния документ и електронните удостоверителни услуги (ЗЕДЕЕУ), с което изразявате Вашата воля да се породят съответните правни последици – да приемете тези Общи условия, да направите ПОРЪЧКА и да сключите съответен ИНДИВИДУАЛЕН ДОГОВОР и др. под. под формата на електронен документ по смисъла на
ЗЕДЕУУ.
&nbsp;
1. 5.2. В отношенията между Вас и БЛОКЧЕЙН ТЕХ (при създаване на АКАУНТ и/или при ПОРЪЧКА и сключване на ИНДИВИДУАЛЕН ДОГОВОР), Вие се съгласявате авторството на електронните изявления да се установява по реда на чл. 13, ал. 1 във връзка с чл. 13, ал. 4 ЗЕДЕУУ. За избягване на всякакво съмнение, въвеждането на адрес на електронна поща и ПАРОЛА при достъп до АКАУНТ ще представляват способ за уникалното Ви идентифициране в качеството Ви на КЛИЕНТ, регистрирал този АКАУНТ, като автор при изпращането на електронни изявления към БЛОКЧЕЙН ТЕХ чрез CryptoDesk. Страните се съгласяват получаваните по този ред електронни изявления да се считат за подписани с обикновен електронен подпис, със силата на саморъчен подпис в отношенията между тях, по смисъла на чл. 3, параграф 10 от Регламент (ЕС) № 910/2014 относно електронната идентификация и удостоверителните услуги при електронни трансакции на вътрешния пазар и за отмяна на Директива 1999/93/ЕО и чл. 13, ал. 4 ЗЕДЕУУ.
&nbsp;
1. 5.3. С приемане на Общите условия Вие се съгласявате комуникацията с Вас по повод ползването на услугите, предоставяни от БЛОКЧЕЙН ТЕХ чрез CryptoDesk, да бъде осъществявана по електронен път, включително и чрез електронна поща, както и да бъдете адресат на електронни изявления от БЛОКЧЕЙН ТЕХ, включително такива, които са автоматично генерирани и/или изпращани чрез CryptoDesk, по електронна поща, кратки текстови съобщения (SMS), в рамките на Вашия АКАУНТ или по друг начин.
&nbsp;
1. 5.4. БЛОКЧЕЙН ТЕХ може да съхранява в лог-файлове на своя сървър Вашия IP адрес, както и всяка друга информация, необходима за идентифицирането Ви като КЛИЕНТ и за възпроизвеждане на електронните Ви изявления (приемане на Общи условия; извършване на ПОРЪЧКИ и сключване на ИНДИВИДУАЛЕН ДОГОВОР и др. под.), в случай на възникване на правен спор или производство.

>**II. СЪЗДАВАНЕ И АКТИВИРАНЕ НА АКАУНТ. KYC ПРОВЕРКА.**

&nbsp; За да можете да използвате услугите, предлагани от БЛОКЧЕЙН ТЕХ на CryptoDesk, за които се изисква регистрация, следва да създадете свой АКАУНТ съгласно т. 6 по-долу, като попълните съответната електронна форма за регистрация, изразите своето съгласие с настоящите Общи условия и потвърдите, че сте се запознал с Политиката за защита на личните данни на БЛОКЧЕЙН ТЕХ, и да преминете успешно през ВЕРИФИКАЦИЯ съгласно т. 7 по-долу.

>**6\. СЪЗДАВАНЕ НА АКАУНТ**

1. 6.1. Регистрацията в CryptoDesk се извършва лично, като не се допуска извършване на регистрация от името на други лица.
&nbsp;
1. 6.2. Всяка информация и документация, изпратена до посочените при регистрацията на АКАУНТ адрес на електронна поща и/или телефонен номер или съответно публикувана в самия АКАУНТ, ще се счита за надлежно изпратена и сведена до Вашето внимание.
&nbsp;
1. 6.3. Не се допуска използването на услугите и/ или създаването на АКАУНТ от лица, които не са навършили 18 години или не са дееспособни. 
&nbsp;
1. 6.4. За да създадете АКАУНТ, следва да попълните всички полета, обозначени като задължителни в съответната електронна форма за регистрация: имена, адрес на електронна поща и други, като се задължавате да предоставите пълни и верни идентификационни данни, както и всяка друга информация, обозначена като задължителна за предоставяне във формата, както и да ги поддържате такива.
&nbsp;
1. 6.5. В случай че не желаете или по друга причина сте възпрепятствани да предоставите всички или някои от поисканите в процеса по регистрация данни или друга информация, Вие няма да можете да продължите и/или завършите процеса по създаване на АКАУНТ, както и да получите достъп до предлаганите от БЛОКЧЕЙН ТЕХ на CryptoDesk услуги, за които се изисква регистрация. В случай че не предоставите изискваните и означени като задължителни в съответната електронна форма данни или
предоставите неверни данни, БЛОКЧЕЙН ТЕХ има право да откаже да Ви регистрира, да прекрати или спре поддържането на регистрацията Ви и достъпа Ви до Вашия АКАУНТ. В такива случаи съответно е възможно и да Ви бъде отказано сключването на ИНДИВИДУАЛЕН ДОГОВОР по подадена от Вас ПОРЪЧКА, съответно започналото вече изпълнение да бъде спряно съгласно предвиденото в тези Общи условия.
&nbsp;
1. 6.6. Задължителна стъпка в процеса по създаване на АКАУНТ е приемането на настоящите Общи условия чрез отбелязване в полето „Съгласен съм с Общите условия за условия за ползване на услугите, достъпни през уебсайта https://cryptodesk.bg и се запознах с Политиката за защита на личните данни на БЛОКЧЕЙН ТЕХ“ и натискане на виртуалния бутон „Регистрация“, което има значението съгласно т. 5 по-горе. За избягване на всякакво съмнение, с натискането на бутона „Регистрация“ Вие извършвате изрично електронно изявление по смисъла на ЗЕДЕУУ за сключване на РАМКОВ ДОГОВОР при условията на тези Общи условия и декларирате, че сте запознат с тях.
&nbsp;
1. 6.7. След като извършите гореописаните действия, БЛОКЧЕЙН ТЕХ незабавно потвърждава получаването на изявлението за създаване на АКАУНТ и сключване на РАМКОВ ДОГОВОР при условията на тези Общи условия, като изпраща електронно съобщение до посочения от Вас при създаването на АКАУНТА адрес на електронна поща. Съобщението за потвърждение съдържа наименование, адрес на управление и данни за кореспонденция с БЛОКЧЕЙН ТЕХ, текста на Общите условия във вид на електронен документ и електронна препратка (линк) за потвърждение на регистрацията и създаването на АКАУНТ. С последване на препратката за потвърждение на регистрацията (клик върху линка), Вие ще бъдете препратени към страница в CryptoDesk за създаване на парола за достъп до Вашия АКАУНТ, със създаването на която Вие завършвате процеса по създаване на АКАУНТ в CryptoDesk.
&nbsp;
1. 6.8. За да бъде активиран Вашият АКАУНТ в CryptoDesk, следва да преминете през процеса по KYC ПРОВЕРКА съгласно т. 7 по-долу. До момента на окончателно успешно преминаване на този процес, Вашият АКАУНТ в CryptoDesk е неактивен, което означава, че не можете да използвате услугите, предоставяни от БЛОКЧЕЙН ТЕХ чрез CryptoDesk, за които се изисква регистрация като например да правите ПОРЪЧКИ и/или да сключвате ИНДИВИДУАЛНИ ДОГОВОРИ с БЛОКЧЕЙН ТЕХ.

>**7\. KYC ПРОВЕРКА**

1. 7.1. В изпълнение на задълженията си по приложимото законодателство относно мерките срещу изпирането на пари и финансиране на тероризма БЛОКЧЕЙН ТЕХ прилага мерки за KYC ПРОВЕРКА на Вас като КЛИЕНТ на БЛОКЧЕЙН ТЕХ при регистрацията Ви в CryptoDesk, както и винаги когато възникне съмнение за верността, актуалността или пълнотата на
представените от Вас данни или документи или съмнение за изпиране на пари и/или за наличие на средства с престъпен произход. Тези мерки включват:
1. 7.1.1. Вашето идентифициране и проверка на Вашата идентификация;
1. 7.1.2. в случаите, когато е относимо и приложимо – събиране на информация и оценка на целта и характера на деловите взаимоотношения, които са установени или предстои да бъдат установени с Вас;
1. 7.1.3. изясняване на произхода на средствата, които използвате за плащания към БЛОКЧЕЙН ТЕХ (включително парични средства или КРИПТОАКТИВИ);
1. 7.1.4. текущо наблюдение, включително и на отношенията с Вас, а при необходимост – актуализиране на относимата за Вас и отношенията информация.
&nbsp;
1. 7.2. Извършването на KYC ПРОВЕРКА, наричана още ВЕРИФИКАЦИЯ, е неразделна част от процеса по установяване на делови отношения или извършване на ОПЕРАЦИИ. Вие потвърждавате, че сте наясно, че в изпълнение на приложимото законодателство БЛОКЧЕЙН ТЕХ има правото да откаже извършването на ОПЕРАЦИЯ в случай на неуспешно преминаване през процеса на ВЕРИФИКАЦИЯ (независимо дали е първоначален или последващ, автоматизиран или не, включително в рамките на допълнителна KYC ПРОВЕРКА), както и временно да ограничи възможността за правене на ПОРЪЧКИ до завършване на съответните проверки или да забави или откаже сключването на и/или изпълнението на сключен ИНДИВИДУАЛЕН ДОГОВОР, ако счете, че е нужна допълнителна ВЕРИФИКАЦИЯ.
&nbsp;
1. 7.3. ВЕРИФИКАЦИЯТА Ви и проверката на предоставените от Вас данни се осъществява, като предоставите следните данни и извършите следните действия съобразно указанията, които се визуализират в CryptoDesk на екрана на Вашето устройство:
1. 7.3.1. да предоставите ясно копие на свой валиден документ за самоличност: паспорт или лична карта, при спазване на указанията за заснемането, които се дават от CryptoDesk;
1. 7.3.2. да позиционирате лицето си пред камерата на Вашето устройство в реално време и да извършите указаните движения, за да позволите снемането на данни за движението на Вашето лице; в случай че не желаете да предоставите такива данни, моля да се свържете с CryptoDesk, за да бъде осигурена възможност от страна на БЛОКЧЕЙН ТЕХ за потвърждаване на самоличността Ви по друг подходящ и приемлив за Вас начин (чрез видео разговор с оператор на БЛОКЧЕЙН ТЕХ);
1. 7.3.3. да предоставите/ попълните свои актуални лични данни съобразно указанията в процеса по ВЕРИФИКАЦИЯ;
1. 7.3.4. да отговорите на поставените въпроси, като предоставите вярна и актуална информация, и да предоставите указаните декларации.
&nbsp;
1. 7.4. KYC ПРОВЕРКАТА може да включва и допълнителни стъпки, както и предоставяне на допълнителна информация, извън посоченото по т. 7.3.
&nbsp;
1. 7.5. В случай че в предоставените от Вас данни, включително под формата на снимки, и/или извършените от Вас действия по т. 7.3 е налице грешка, непълнота или друга неизправност (например лошо качество на снимка), ще бъдете помолени да предоставите съответните данни отново и да преминете отново през оставащите стъпки от процеса по ВЕРИФИКАЦИЯ.
&nbsp;
1. 7.6. Вие разбирате, че в случай че не желаете или по друга причина сте възпрепятствани да предоставите всички или някои от поисканите в процеса по ВЕРИФИКАЦИЯ (включително т. 7.4 по-горе) данни или друга информация, Вие няма да може да продължите и/или завършите процеса по ВЕРИФИКАЦИЯ в зависимост от етапа, на който се намирате. В такъв случай няма да получите достъп до извършването на ОПЕРАЦИИ през CryptoDesk и предлаганите от БЛОКЧЕЙН ТЕХ услуги, за които се изисква
регистрация.
&nbsp;
1. 7.7. ВЕРИФИКАЦИЯТА се счита за завършена, когато Ви бъде назначен окончателен статус "Отказан" или "Одобрен" в системата на CryptoDesk. Вие разбирате и потвърждавате, че по преценка на БЛОКЧЕЙН ТЕХ, при прилагане на основан на риска подход в резултат от извършената ВЕРИФИКАЦИЯ, БЛОКЧЕЙН ТЕХ има правото да откаже да Ви одобри и по този начин да откаже да предостави достъп до извършването на ОПЕРАЦИИ през CryptoDesk и предлаганите от БЛОКЧЕЙН ТЕХ УСЛУГИ.

>**8\. СКЛЮЧВАНЕ НА РАМКОВ ДОГОВОР**

1. 8.1. РАМКОВИЯТ ДОГОВОР между Вас и БЛОКЧЕЙН ТЕХ се счита сключен от момента на създаването на АКАУНТ, като е сключен на български език. Текстът на РАМКОВИЯ ДОГОВОР и тези Общите условия е достъпен за съхраняване на Вашето устройство по начин, който позволява неговото сваляне и съхраняване на локално устройство, както и последващо възпроизвеждане в CryptoDesk тук: https://cryptodesk.bg/terms.
&nbsp;
1. 8.2. С успешното преминаване през процеса по ВЕРИФИКАЦИЯ, Вашият АКАУНТ се активира и може да бъде използван за достъп до услугите, предлагани на CryptoDesk, включително за заявяване на ПОРЪЧКИ.
&nbsp;
1. 8.3. В случай че не инициирате, прекъснете или не успеете да преминете успешно през процеса по ВЕРИФИКАЦИЯ, Вашият АКАУНТ не може да бъде активиран. БЛОКЧЕЙН ТЕХ запазва съществуването на неактивен АКАУНТ в срок до 3 (три) месеца от създаването на АКАУНТА, след което го премахва, като сключеният между Вас и БЛОКЧЕЙН ТЕХ РАМКОВ ДОГОВОР се счита за автоматично прекратен, считано от тази дата.

>**9\. ПОТРЕБИТЕЛСКИ АКАУНТ И ПРЕКРАТЯВАНЕ НА РЕГИСТРАЦИЯТА**

1. 9.1. Вие имате право на достъп до Вашия АКАУНТ и посредством него – до услугите, предоставяни от БЛОКЧЕЙН ТЕХ чрез CryptoDesk, при спазване на тези Общи условия и на изискванията за достъп, определени от БЛОКЧЕЙН ТЕХ.
&nbsp;
1. 9.2. Достъпът до Вашия АКАУНТ се осъществява чрез въвеждане на посочените при създаването му адрес на електронна поща и ПАРОЛА. Вие се задължавате да полагате всички грижи и да предприемете необходимите мерки, които разумно се налагат с цел опазване на Вашата ПАРОЛА, включително като не я разкривате на трети лица.
&nbsp;
1. 9.3. Вие носите пълна отговорност за всички действия, които се извършват чрез Вашия АКАУНТ от Вас или от което и да е трето лице чрез използване на Вашите данни и ПАРОЛА. Всички действия, извършени през Вашия АКАУНТ и/или чрез използване на Вашата ПАРОЛА, се считат за извършени от Вас.
&nbsp;
1. 9.4. Вие се задължавате да уведомите незабавно БЛОКЧЕЙН ТЕХ в случай на неправомерен достъп, както и при вероятност или подозрение за такъв, до Вашия АКАУНТ и/или ПАРОЛА, например в случай на ЗЛОУМИШЛЕНИ ДЕЙСТВИЯ от трети лица, при изгубване или кражба на мобилно устройство или персонален компютър, изгубване или кражба на ПАРОЛА и други.
&nbsp;
1. 9.5. Във Вашия АКАУНТ, Вие имате достъп до предоставената в хода на създаване на АКАУНТА информация относно: Вашите имена, адрес на електронна поща, ПАРОЛА и статус на ВЕРИФИКАЦИЯ.
&nbsp;
1. 9.6. Вие имате възможност да смените Вашата ПАРОЛА, чрез функционалностите на Вашия АКАУНТ. В случай на промяна на Ваши лични данни и/или друга информация, събрана в процеса по ВЕРИФИКАЦИЯ и отнасяща се до отношенията Ви с БЛОКЧЕЙН ТЕХ, Вие се задължавате да уведомите БЛОКЧЕЙН ТЕХ чрез съответните форми във Вашия АКАУНТ и/или като се свържете с БЛОКЧЕЙН ТЕХ по начините, посочени в т. 20 по-долу. Вие разбирате, че в такъв случай е възможно да бъдете помолени отново да преминете през процес по ВЕРИФИКАЦИЯ с цел актуализиране на съответните данни и/или информация, като и тук се прилагат правилата, регламентиращи процеса по ВЕРИФИКАЦИЯ, включително т. 7.2 и т. 8.3.
&nbsp;
1. 9.7. Вие можете по Ваша преценка да поискате прекратяване на регистрацията си. След прекратяване на регистрацията, РАМКОВИЯТ ДОГОВОР с Вас се счита за автоматично прекратен от датата на прекратяване на Вашата регистрация. За избягване на съмнение, прекратяването на регистрацията не засяга действието на валидно сключен(ите) преди това ИНДИВИДУАЛНИ ДОГОВОР(И). Вие не можете да инициирате прекратяване на регистрацията и закриване на Вашия АКАУНТ при наличието на сключен, но все още неизпълнен ИНДИВИДУАЛЕН ДОГОВОР с БЛОКЧЕЙН ТЕХ. В последния случай, регистрацията може да бъде прекратена само след изпълнение на ИНДИВИДУАЛНИЯ/ИТЕ ДОГОВОР/И
или неговото/ тяхното прекратяване на някое от основанията по т. 17.3 по-долу.
&nbsp;
1. 9.8. С прекратяване на регистрацията БЛОКЧЕЙН ТЕХ преустановява достъпа Ви до Вашия АКАУНТ.
&nbsp;
1. 9.9. Вие се задължавате да прекратявате сесията, при която сте влезли в АКАУНТА си, чрез натискане на виртуалния бутон „Изход“.

>**10\.  ДОПЪЛНИТЕЛНА KYC ПРОВЕРКА**

1. 10.1. В хода на отношенията между Вас и БЛОКЧЕЙН ТЕХ при и по повод изпълнението на сключен с Вас ИНДИВИДУАЛЕН ДОГОВОР е възможно да възникне необходимост от извършването на допълнителна KYC ПРОВЕРКА. В такива случаи Вие се съгласявате, че ще съдействате на БЛОКЧЕЙН ТЕХ с извършване на определени действия и предоставяне на информация, включително, но не само:
1. 10.1.1. преминаване през нов процес на ВЕРИФИКАЦИЯ съгласно т. 7 по-горе;
1. 10.1.2. предоставяне на допълнителни документи и данни, свързани с и/или по повод конкретни ОПЕРАЦИИ;
1. 10.1.3. предоставяне на всякаква друга информация и/или документи, необходими на БЛОКЧЕЙН ТЕХ за изпълнение на неговите задължения по приложимото законодателство в областта на мерките срещу изпирането на пари и срещу финансирането на тероризма.
&nbsp;
1. 10.2. Вие потвърждавате и се съгласявате, че в процеса на допълнителната KYC ПРОВЕРКА, БЛОКЧЕЙН ТЕХ има правата по т. 7.2 и т. 8.3.

>**III. ПОРЪЧКИ И СКЛЮЧВАНЕ НА ИНДИВИДУАЛЕН ДОГОВОР.**

>**11\.  ПОРЪЧКИ И ПОТВЪРЖДАВАНЕ НА ПОРЪЧКИ**

&nbsp; За да се възползвате от предлаганите от БЛОКЧЕЙН ТЕХ услуги по обмяна на КРИПТОАКТИВИ, Вие следва да заявите ПОРЪЧКА за покупка или продажба на КРИПТОАКТИВИ.

1. 11.1. За целите на подаване на ПОРЪЧКА, сключването на съответния ИНДИВИДУАЛЕН ДОГОВОР и неговото изпълнение, Вие следва да притежавате ПОРТФЕЙЛ ЗА КРИПТОАКТИВИ. БЛОКЧЕЙН ТЕХ не предоставя услуги по създаване или поддържане на ПОРТФЕЙЛИ ЗА КРИПТОАКТИВИ, не ги контролира, не ги проверява и не носи никаква отговорност за достъпа до посочен(ите) от Вас ПОРТФЕЙЛ(И) ЗА КРИПТОАКТИВИ и за отношенията Ви със съответните доставчици на услуги, свързани с ПОРТФЕЙЛ(И) ЗА КРИПТОАКТИВИ.
&nbsp;
1. 11.2. За улеснение на своите КЛИЕНТИ, БЛОКЧЕЙН ТЕХ може да постави в CryptoDesk препратки (линкове) към уебсайтовете на популярни доставчици на услуги за ПОРТФЕЙЛИ ЗА КРИПТОАКТИВИ. Вие следва сами да се информирате и да преценявате дали да използвате някой от тези ПОРТФЕЙЛИ ЗА КРИПТОАКТИВИ, като БЛОКЧЕЙН ТЕХ не носи отговорност за отношенията между Вас и съответния доставчик на услуги, свързани с ПОРТФЕЙЛ(И) ЗА КРИПТОАКТИВИ, нито за качеството и сигурността на услугите, предоставяни от тях.
&nbsp;
1. 11.3. За да заявите ПОРЪЧКА за покупка на КРИПТОАКТИВИ, Вие следва да попълните съответната електронна форма в CryptoDesk, като посочите: (i) количеството от избрания КРИПТОАКТИВ, който желаете да закупите, и (ii) адрес на ПОРТФЕЙЛ ЗА КРИПТОАКТИВИ, по който желаете да бъдат прехвърлени закупените КРИПТОАКТИВИ. БЛОКЧЕЙН ТЕХ не носи отговорност в случай на каквито и да било грешки или неточности във въведените от Вас данни за адреса на посочения от Вас ПОРТФЕЙЛ ЗА КРИПТОАКТИВИ, нито в случай на невъзможност от Ваша страна да достъпвате до или оперирате с този ПОРТФЕЙЛ ЗА КРИПТОАКТИВИ или неговата наличност. БЛОКЧЕЙН ТЕХ не носи отговорност в случай че посоченият от Вас ПОРТФЕЙЛ ЗА КРИПТОАКТИВИ принадлежи на друго лице; в тези случаи БЛОКЧЕЙН ТЕХ има правото да поиска допълнителна информация от Вас, включително отнасяща се до лицето, на което принадлежи и/или което има правото да оперира с посочения ПОРТФЕЙЛ ЗА КРИПТОАКТИВИ, както и да откаже да изпълни заявената ПОРЪЧКА.
&nbsp;
1. 11.4. За да заявите ПОРЪЧКА за продажба на КРИПТОАКТИВИ, Вие следва да попълните съответната електронна форма в CryptoDesk, като посочите: (i) количеството от избрания КРИПТОАКТИВ, който желаете да продадете, и (ii) данни за банковата сметка – IBAN и пълни данни на титуляря на сметката, по която желаете да получите цената на продадените КРИПТОАКТИВИ. БЛОКЧЕЙН ТЕХ не носи отговорност в случай на каквито и да било грешки или неточности във въведените от Вас данни за банковата сметка, по която желаете да получите цената на продадените КРИПТОАКТИВИ, нито в случай че сте в невъзможност да оперирате със сметката или наличността по нея. БЛОКЧЕЙН ТЕХ не носи отговорност в случай че посочената от Вас банкова сметка принадлежи на друго лице; в тези случаи БЛОКЧЕЙН ТЕХ има правото да поиска допълнителна информация от Вас, включително отнасяща се до лицето, на което принадлежи и/или което има правото да оперира с посочената банкова сметка, както и да откаже да изпълни заявената ПОРЪЧКА.
&nbsp;
1. 11.5. При въвеждане на количеството от избрания КРИПТОАКТИВ, което желаете да закупите или да продадете, CryptoDesk автоматично – посредством калкулатор, който взема данни от някои от големите борси за КРИПТОАКТИВИ и двойки КРИПТОАКТИВИ по света, както и курс за превалутиране в лева (BGN) от OANDA, Ви представя информация за:
1. 11.5.1. общата сума в лева (BGN) за извършване на ОПЕРАЦИЯТА по покупка или продажба на въведеното количество КРИПТОАКТИВИ към момента на въвеждане на съответното количество в калкулатора;
1. 11.5.2. пазарната стойност на единица от избрания КРИПТОАКТИВ към момента на въвеждане на съответното количество в калкулатора;
1. 11.5.3. възнаграждението на БЛОКЧЕЙН ТЕХ за извършената услуга, изразено като процент, към момента на въвеждане на съответното количество в калкулатора;
1. 11.5.4. общата сума в лева (BGN) за покупка/продажба на единица от избрания КРИПТОАКТИВ с включено възнаграждение на БЛОКЧЕЙН ТЕХ към момента на въвеждане на съответното количество в калкулатора.
&nbsp;
1. 11.6. Посочените в т. 11.5 суми се отнасят към момента на въвеждане на съответното количество КРИПТОАКТИВИ в калкулатора и в този смисъл са ориентировъчни за КЛИЕНТА, като дават информация за начина, по който ще се изчислят същите към момента на извършване на ОПЕРАЦИЯТА. Като КЛИЕНТ на БЛОКЧЕЙН ТЕХ, Вие потвърждавате, че сте наясно с и се съгласявате, че изцяло поемате и носите риска от възможна промяна в пазарната стойност на избрания КРИПТОАКТИВ в промеждутъка между извършване на ПОРЪЧКАТА и извършване на ОПЕРАЦИЯТА.
&nbsp;
1. 11.7. Вие можете да променяте данните, които попълвате в електронната форма за ПОРЪЧКА многократно до нейното завършване по реда на т. 11.8 по-долу.
&nbsp;
1. 11.8. Вие завършвате ПОРЪЧКАТА за покупка или продажба на КРИПТОАКТИВИ чрез натискане на виртуалния бутон „Потвърди“. С натискане на виртуалния бутон „Потвърди“ ПОРЪЧКАТА се счита за окончателно извършена и не можете да нанасяте промени в нея,
включително по отношение на желаното количество КРИПТОАКТИВИ, което сте поръчали за покупка или продажба. С това действие Вие извършвате изявление за сключване на ИНДИВИДУАЛЕН ДОГОВОР за покупка или продажба на КРИПТОАКТИВИ с БЛОКЧЕЙН ТЕХ.
&nbsp;
1. 11.9. След извършване на ПОРЪЧКАТА от Ваша страна, БЛОКЧЕЙН ТЕХ изпраща автоматично електронно съобщение до Вашия адрес на електронна поща за получаване на ПОРЪЧКАТА. Съобщението съдържа описание на ПОРЪЧКАТА, включително сумите по т. 11.6, и потвърждение за получаването й. За избягване на всякакво съмнение, потвърждението за получаване на ПОРЪЧКАТА не е потвърждение за сключване ИНДИВИДУАЛЕН ДОГОВОР от страна на БЛОКЧЕЙН ТЕХ.
&nbsp;
1. 11.10. След получаване на ПОРЪЧКАТА, БЛОКЧЕЙН ТЕХ извършва проверка съобразно приложимото законодателство и има правото да поиска допълнителна KYC ПРОВЕРКА съгласно т. 10 по-горе.
&nbsp;
1. 11.11. Когато са представени всички необходими данни и документи от Ваша страна, БЛОКЧЕЙН ТЕХ изпраща електронно съобщение до Вашия адрес на електронна поща за потвърждение на ПОРЪЧКАТА. В съобщението се включват и данни за необходимите последващи действия за изпълнение на ИНДИВИДУАЛНИЯ ДОГОВОР, включително информация за банковата сметка на БЛОКЧЕЙН ТЕХ, съответно адрес на ПОРТФЕЙЛА ЗА КРИПТОАКТИВИ на БЛОКЧЕЙН ТЕХ, по която/който следва да преведете общата сума за извършване на ОПЕРАЦИЯТА или съответното количество КРИПТОАКТИВИ.
&nbsp;
1. 11.12. Вие се задължавате да не предприемате действия по прехвърляне на суми за покупка на КРИПТОАКТИВИ, съответно – КРИПТОАКТИВИ за продажба, до момента на получаване на съобщението за потвърждение на ПОРЪЧКАТА от страна на БЛОКЧЕЙН ТЕХ по т. 11.11.

>**12\.  СКЛЮЧВАНЕ НА ИНДИВИДУАЛЕН ДОГОВОР**

1. 12.1. ИНДИВИДУАЛНИЯТ ДОГОВОР между Вас и БЛОКЧЕЙН ТЕХ се сключва и поражда действие от момента на потвърждаване на ПОРЪЧКАТА Ви от страна на БЛОКЧЕЙН ТЕХ със съобщението за потвърждение на ПОРЪЧКАТА по т. 11.11 по-горе.
&nbsp;
1. 12.2. Вие се задължавате да преведете общата сума за извършване на ОПЕРАЦИЯТА по банковата сметка на БЛОКЧЕЙН ТЕХ, съответно да прехвърлите КРИПТОАКТИВИТЕ по ПОРТФЕЙЛА ЗА КРИПТОАКТИВИ на БЛОКЧЕЙН ТЕХ, в първия възможен момент след получаване на съобщението за потвърждение на направената ПОРЪЧКА по т. 11.11 по-горе, но не по-късно от 72 часа от неговото получаване. В случай че не преведете сумата или КРИПТОАКТИВИТЕ в този срок, ИНДИВИДУАЛНИЯТ ДОГОВОР между Вас и БЛОКЧЕЙН ТЕХ се счита за автоматично прекратен.
&nbsp;
1. 12.3. За избягване на всякакво съмнение, БЛОКЧЕЙН ТЕХ не носи отговорност за последиците от забавата Ви да преведете общата сума за извършване на ОПЕРАЦИЯТА, съответно да прехвърлите КРИПТОАКТИВИТЕ, включително по отношение промяната на пазарната стойност на закупените/продадените КРИПТОАКТИВИ в сравнение със стойността на последните към момента на ПОРЪЧКАТА.
&nbsp;
1. 12.4. Срокът за изпълнение на заявената с Вашата ПОРЪЧКА ОПЕРАЦИЯ 1 (един) работен ден, считано от момента, в който е заверена банковата сметка на БЛОКЧЕЙН ТЕХ с преведената от Вас сума или съответно прехвърлените от Вас КРИПТОАКТИВИ към БЛОКЧЕЙН ТЕХ са постъпили по ПОРТФЕЙЛА ЗА КРИПТОАКТИВИ на БЛОКЧЕЙН ТЕХ и е налице минимално необходимият според съответната борса на КРИПТОАКТИВИ (Bitstamp или друга) брой потвърждения за прехвърлянето им, автоматично генерирани в мрежата (block confirmations). За избягване на съмнение, минимално изискуемият брой потвърждения за Биткойни е 3 (три), а за Етериум – 15 (петнадесет). В случаите, когато този момент е настъпил в извън работно време (т.е. във време извън периода от 09:00 до 17:30 часа (EET), от понеделник до петък и/или по време на официален празник/ официален неработен ден за територията на Република България) – срокът за изпълнение на започва да тече считано от 09:00 часа на следващия работен ден, а когато този момент е настъпил през нощта (от 22:00 до 06:00 часа) – този срок започва да тече от 12:00 часа на следващия работен ден
&nbsp;
1. 12.5. Срокът за изпълнение спира да тече, в случай че:
1. 12.5.1. БЛОКЧЕЙН ТЕХ установи несъответствие, неточност или непълнота в предоставените от Вас данни;
1. 12.5.2. БЛОКЧЕЙН ТЕХ по закон е длъжно да изиска допълнителни данни или да извърши допълнителни проверки, преди да извърши ОПЕРАЦИЯТА;
1. 12.5.3. възникне НЕПРЕОДОЛИМА СИЛА;
1. 12.5.4. са налице действия или бездействия на трети лица, които влияят върху изпълнението на ОПЕРАЦИЯТА и които са извън контрола на БЛОКЧЕЙН ТЕХ като например действия във връзка с извършване на банков превод от страна на банките на платеца или получателя, или друга страна, чрез която се извършва плащането, задържане на превода от страна на такава трета страна, временно преустановяване на дейността на определена борса за КРИПТОАКТИВИ и други подобни.
&nbsp;
1. 12.6. При ПОРЪЧКА за ОПЕРАЦИЯ на стойност над 10 000 лв., срокът за извършване на ОПЕРАЦИЯТА е до 3 (три) работни дни, като БЛОКЧЕЙН ТЕХ Ви уведомява за конкретния очакван срок в съобщението за потвърждение на съответната ПОРЪЧКА, като Ви предоставя възможност да потвърдите ПОРЪЧКАТА при тези условия или да я откажете. При
потвърждение от Ваша страна на ПОРЪЧКАТА срокът за изпълнение започва да тече съобразно правилата, предвидени в т. 12.4.

>**13\.  ЦЕНИ, ТАКСИ И РАЗХОДИ**

1. 13.1. Информация за възнаграждението на БЛОКЧЕЙН ТЕХ се съдържа в Тарифата на БЛОКЧЕЙН ТЕХ, достъпна [тук](https://cryptodesk.bg/fees). БЛОКЧЕЙН ТЕХ си запазва правото да променя по всяко време посочените в Тарифата възнаграждения за предоставяне на услугите по покупка/продажба на КРИПТОАКТИВИ, като това не засяга вече сключени ИНДИВИДУАЛНИ ДОГОВОРИ. За избягване на съмнение, в случай на промяна на приложимото възнаграждение в промеждутъка между получаване на ПОРЪЧКА и нейното потвърждаване, се прилага обозначеният към момента на завършване на ПОРЪЧКАТА размер на възнаграждение.
&nbsp;
1. 13.2. БЛОКЧЕЙН ТЕХ извършва ОПЕРАЦИЯТА по следния начин:
1. 13.2.1. при покупка на КРИПТОАКТИВИ – след получаване на общата сума за извършване на ОПЕРАЦИЯТА, БЛОКЧЕЙН ТЕХ удържа от постъпилата сума своето възнаграждение в размера, посочен в потвърдената ПОРЪЧКА на КЛИЕНТА, като закупува КРИПТОАКТИВИ с остатъка от тази сума на избрана от БЛОКЧЕЙН ТЕХ борса за КРИПТОАКТИВИ;
1. 13.2.2. при продажба на КРИПТОАКТИВИ – след получаване на съответното количество КРИПТОАКТИВИ, БЛОКЧЕЙН ТЕХ продава това количество на избрана от БЛОКЧЕЙН ТЕХ борса за КРИПТОАКТИВИ, като от получената сума удържа своето възнаграждение в размера, посочен в потвърдената ПОРЪЧКА на КЛИЕНТА.
&nbsp;
1. 13.3. Цената на КРИПТОАКТИВИТЕ, по която БЛОКЧЕЙН ТЕХ извършва съответната ОПЕРАЦИЯ по т. 13.2 по-горе, е съответната борсова цена към момента на извършване на ОПЕРАЦИЯТА. Вие се съгласявате и приемате, че търговията с КРИПТОАКТИВИ в свързана с рискове от динамични и непредвидими пазарни изменения, поради което нямате право на компенсации или претенции в случай на разлика в борсовата цена на КРИПТОАКТИВИТЕ, приложима към момента на ПОРЪЧКАТА, и тази, приложима към момента на ОПЕРАЦИЯТА.
&nbsp;
1. 13.4. Вие не дължите възстановяване на разходи на БЛОКЧЕЙН ТЕХ, които не са включени в условията на сключения помежду Ви ИНДИВИДУАЛЕН ДОГОВОР и не произтичат от реализиране на Вашата отговорност.

>**IV. ПРАВА И ЗАДЪЛЖЕНИЯ НА СТРАНИТЕ ПО СКЛЮЧЕНИТЕ ДОГОВОРИ. ОТГОВОРНОСТ. ПРАВО НА ОТКАЗ И ПРЕКРАТЯВАНЕ НА РАМКОВИЯ ДОГОВОР.**

>**14\.  ПРАВА И ЗАДЪЛЖЕНИЯ НА СТРАНИТЕ**

&nbsp; Права и задължения на КЛИЕНТА по сключения РАМКОВ ДОГОВОР

1. 14.1. Вие имате право:

1. 14.1.1. на достъп до съдържанието, публикувано в CryptoDesk, единствено за лично ползване, при използване на нормалните функционалности на CryptoDesk;
1. 14.1.2. по всяко време по своя преценка да прекратите използването на предоставяните от БЛОКЧЕЙН ТЕХ чрез CryptoDesk услуги, като прекратите регистрацията си.
&nbsp;
1. 14.2. Вие се задължавате при използване на предоставяните от БЛОКЧЕЙН ТЕХ услуги чрез CryptoDesk:
1. 14.2.1. да спазвате приложимото законодателство, Общите условия и Интернет етиката;
1. 14.2.2. да използвате CryptoDesk и услугите, до които имате достъп чрез CryptoDesk, само по предназначение и съобразно изискванията на приложимото законодателство и тези Общи условия;
1. 14.2.3. да не се представяте за друго лице или по друг начин да заблуждавате БЛОКЧЕЙН ТЕХ или трети лица относно самоличността си и да не действате от името на лица, за чието представителство нямате нужната представителна власт в изискуемия съгласно приложимото законодателство обем;
1. 14.2.4. да не нарушавате чужди имуществени или неимуществени права, включително ПРАВА НА ИНТЕЛЕКТУАЛНА СОБСТВЕНОСТ;
1. 14.2.5. да предоставяте вярна, точна и пълна информация, изисквана от БЛОКЧЕЙН ТЕХ съгласно РАМКОВИЯ ДОГОВОР и действащото законодателство, при регистрацията и идентификацията си в рамките на процеса по ВЕРИФИКАЦИЯ и/или допълнителна KYC ПРОВЕРКА, както и при всяко друго използване на CryptoDesk и/или услугите, предоставяни от БЛОКЧЕЙН ТЕХ чрез CryptoDesk;
1. 14.2.6. да уведомявате незабавно БЛОКЧЕЙН ТЕХ за всеки случай на извършено или открито нарушение при използване на CryptoDesk;
1. 14.2.7. да преустановите използването на CryptoDesk в случай на съмнение за каквото и да е компрометиране на данните за достъп до Вашия АКАУНТ или в случай на загуба на Вашето устройство, с което използвате услугите на CryptoDesk, като уведомите БЛОКЧЕЙН ТЕХ;
1. 14.2.8. да не извършвате ЗЛОУМИШЛЕНИ ДЕЙСТВИЯ;
1. 14.2.9. да не се намесвате в правилното действие на CryptoDesk, включително, но не само, да не осуетявате процедурата по идентификация на друг клиент, да не осъществявате достъп извън предоставения Ви, да не възпрепятствате други клиенти да използват CryptoDesk, както и да не използвате CryptoDesk по начин, предизвикващ отказ от услугите („denial of service”);
1. 14.2.10. да не заобикаляте, повреждате или по друг начин да смущавате нормалната работа на технически или софтуерни приложения, поставени от БЛОКЧЕЙН ТЕХ на CryptoDesk с оглед предотвратяване или ограничаване на ползването на съдържанието на CryptoDesk.

>**Права и задължения на КЛИЕНТА по сключен ИНДИВИДУАЛЕН ДОГОВОР**

1. 14.3. Вие имате правото да получите закупените от Вас КРИПТОАКТИВИ или съответно стойността на продадените от Вас КРИПТОАКТИВИ съобразно условията на съответния ИНДИВИДУАЛЕН ДОГОВОР.
&nbsp;
1. 14.4. Вие се задължавате:
1. 14.4.1. да предоставите на БЛОКЧЕЙН ТЕХ изисканата информация и документи съобразно предвиденото в тези Общи условия;
1. 14.4.2. да приемете прехвърлените от БЛОКЧЕЙН ТЕХ КРИПТОАКТИВИ или платената от БЛОКЧЕЙН ТЕХ цена.

>**Права и задължения на БЛОКЧЕЙН ТЕХ**

1. 14.5. БЛОКЧЕЙН ТЕХ има право да поставя електронни препратки (линкове) към други Интернет страници и ресурси и рекламни банери за продажба на стоки и предоставяне на услуги от трети лица, включително електронни препратки (линкове) и рекламни банери, сочещи към уебсайтове, намиращи се извън контрола на БЛОКЧЕЙН ТЕХ, включително да поставя такива електронни препратки и рекламни банери в АКАУНТИТЕ.
&nbsp;
1. 14.6. БЛОКЧЕЙН ТЕХ няма задължението и обективната възможност да контролира начина, по който използвате предоставяните услуги, и не носи отговорност за Вашата дейност във връзка с използване на услугите, предлагани чрез CryptoDesk, освен в случаите и в степента, изрично предвидени в приложимото законодателство.
&nbsp;
1. 14.7. БЛОКЧЕЙН ТЕХ има правото незабавно и без предизвестие да блокира достъпа Ви до Вашия АКАУНТ, в случай че по преценка на БЛОКЧЕЙН ТЕХ Вие нарушавате разпоредби на действащото законодателство или на настоящите Общи условия.
&nbsp;
1. 14.8. БЛОКЧЕЙН ТЕХ е длъжен да изпълнява направените от Вас и потвърдени от БЛОКЧЕЙН ТЕХ ПОРЪЧКИ съобразно условията на съответните ИНДИВИДУАЛНИ ДОГОВОРИ, сключени с Вас.
&nbsp;
1. 14.9. Когато БЛОКЧЕЙН ТЕХ получи информация, която дава основание да се предположи, че поведението Ви при ползването на услугите, предоставяни от БЛОКЧЕЙН ТЕХ чрез CryptoDesk, би могло да представлява престъпление или нарушение на приложимото законодателство, БЛОКЧЕЙН ТЕХ има право по своя преценка, а в определени случаи и
задължението, да сезира компетентните държавни органи, като им предостави необходимото съдействие и цялата необходима информация и материали по надлежния ред, които биха помогнали за идентифициране на извършителя и разследване на деянието. Във връзка с тези права и задължения, БЛОКЧЕЙН ТЕХ може временно да спре всеки един процес по сключения РАМКОВ ДОГОВОР и/или по сключен ИНДИВИДУАЛЕН ДОГОВОР, включително, но не само: процес по създаване и активиране на АКАУНТ, сключване на ИНДИВИДУАЛЕН ДОГОВОР, изпълнение на ИНДИВИДУАЛЕН ДОГОВОР и др.
&nbsp;
1. 14.10. БЛОКЧЕЙН ТЕХ не дължи и не предоставя каквито и да са консултации или съвети на посетителите и/или КЛИЕНТИТЕ на CryptoDesk във връзка с ОПЕРАЦИИТЕ или по други въпроси, свързани с покупката или продажбата на КРИПТОАКТИВИ.
&nbsp;
1. 14.11. БЛОКЧЕЙН ТЕХ си запазва правото да преустановява предоставянето на конкретни услуги, достъпни чрез CryptoDesk, след отправяне на предизвестие чрез публикуване на съобщение в съответните интернет страници в CryptoDesk и/или в АКАУНТА на своите КЛИЕНТИ. За избягване на всяко съмнение, това не засяга вече подадени ПОРЪЧКИ.

>**15\.  ОТКАЗ ОТ ДОГОВОРА**

&nbsp; (Текстовете на настоящата т. 15 се прилагат само за КЛИЕНТИ, които имат качеството на и действат като потребители по смисъла на Закона за защита на потребителя (ЗЗП))

1. 15.1. Съгласно действащото законодателство за защита на потребителите в Европейския съюз, в случаите когато потребител по смисъла на законодателството за защита на потребителите сключва договор от разстояние или извън търговския обект, той има право в 14-дневен срок, считано от датата на сключването на договора, без да дължи обезщетение или неустойка и без да посочва причина, да се откаже от договора.
&nbsp;
1. 15.2. Предвиденото в приложимото законодателство право на потребителя да се откаже от договор, сключен от разстояние или извън търговския обект, не се прилага по отношение на сключения между Вас и БЛОКЧЕЙН ТЕХ ИНДИВИДУАЛЕН ДОГОВОР, тъй като цената на КРИПТОАКТИВИТЕ, които са предмет на услугите по ИНДИВИДУАЛНИЯ ДОГОВОР, зависи от колебанията на финансовия пазар, които не могат да бъдат контролирани
от БЛОКЧЕЙН ТЕХ и които могат да настъпят по време на срока за упражняване правото на отказ.

>**16\.  ОТГОВОРНОСТ. ОСВОБОЖДАВАНЕ ОТ ОТГОВОРНОСТ.**

1. 16.1. БЛОКЧЕЙН ТЕХ не гарантира, че достъпът до уебсайта CryptoDesk ще бъде непрекъсваем, навременен, сигурен и свободен от грешки.
&nbsp;
1. 16.2. С приемането на настоящите Общи условия Вие декларирате, че използването на услугите на CryptoDesk е изцяло на Ваш риск и отговорност, като потвърждавате, че БЛОКЧЕЙН ТЕХ не носи отговорност за каквито и да било вреди, които биха могли да бъдат понесени от Вас в хода на използването на услугите на CryptoDesk, освен ако тези вреди са причинени от БЛОКЧЕЙН ТЕХ умишлено или се дължат на груба небрежност.
&nbsp;
1. 16.3. Вие се задължавате да обезщетите БЛОКЧЕЙН ТЕХ и всички трети лица за всички претърпени от тях вреди и пропуснати ползи, включително за платени имуществени санкции, адвокатски възнаграждения, разноски за водене на дела и други разходи, вследствие на предявени искове от и/или платени обезщетения на трети лица във връзка с Ваши действия/бездействия при ползването на услугите, предоставяни чрез CryptoDesk, в нарушение на българското законодателство, приложимите чужди закони, тези Общи условия или добрите нрави, включително в случай на ползване на тези услуги от трети лица, на които Вие сте предоставили ПАРОЛАТА си за достъп до Вашия АКАУНТ.
&nbsp;
1. 16.4. С приемането на настоящите Общи условия Вие потвърждавате, че БЛОКЧЕЙН ТЕХ не носи отговорност за непредоставяне на услугите поради обстоятелства извън неговия контрол – в случаи на НЕПРЕОДОЛИМА СИЛА, проблеми в глобалната мрежа интернет и в
предоставянето на услугите извън контрола на БЛОКЧЕЙН ТЕХ, проблеми, дължащи се на Вашето оборудване, както и в случай на неоторизиран достъп или намеса на трети лица в работата на информационната система или сървърите на БЛОКЧЕЙН ТЕХ.
&nbsp;
1. 16.5. По-специално, БЛОКЧЕЙН ТЕХ не отговаря за евентуално причинени вреди на свой КЛИЕНТ, в случай че:
1.  16.5.1. сте предоставили непълни, неточни или неактуални данни или предоставеният адрес на ПОРТФЕЙЛА ЗА КРИПТОАКТИВИ или банкова сметка не Ви принадлежат, или сте в невъзможност да оперирате с тях;
1. 16.5.2. съответната ОПЕРАЦИЯ не е изпълнена, когато това не се дължи на виновни противоправни действия на БЛОКЧЕЙН ТЕХ (вкл. когато е налице НЕПРЕОДОЛИМА СИЛА, законово основание БЛОКЧЕЙН ТЕХ да откаже сключването или изпълнението на сделката, повреда в комуникационните системи, авария, загуба на информация и т.н.);
1. 16.5.3. цената за придобиване/прехвърляне на съответното избрания КРИПТОАКТИВ се измени след направената от Вас ПОРЪЧКА, независимо в каква посока;
1. 16.5.4. прехвърленото от БЛОКЧЕЙН ТЕХ към Вас количество КРИПТОАКТИВИ не е налично по посочения от Вас при ПОРЪЧКАТА ПОРТФЕЙЛ ЗА КРИПТОАКТИВИ или не може да бъде ползвано от Вас поради причини извън контрола на БЛОКЧЕЙН ТЕХ
(включително, но не само: проблеми със сигурността на ПОРТФЕЙЛА ЗА КРИПТОАКТИВИ или ползваните от Вас системи, намеса от трети лица, включително властнически органи, зловреден софтуер, наложени запори върху ПОРТФЕЙЛА ЗА КРИПТОАКТИВИ, загуба на информация, загуба на данни към частния Ви ключ, законови ограничения в съответна държава и т.н.).
&nbsp;
1. 16.6. Предвид спецификата на предоставяните от БЛОКЧЕЙН ТЕХ услуги и липсата на предвидимост, БЛОКЧЕЙН ТЕХ не носи отговорност за пропуснати от Вас ползи при осъществяване (или неосъществяване) на ОПЕРАЦИИ по подадени към БЛОКЧЕЙН ТЕХ ПОРЪЧКИ.
&nbsp;
1. 16.7. БЛОКЧЕЙН ТЕХ не Ви дължи заплащане или възстановяване на каквито и да са такси и данъци, задължението за които може да произтича от законодателството на която и да е държава.
&nbsp;
1. 16.8. В случай че БЛОКЧЕЙН ТЕХ претърпи каквито и да са вреди, включително репутационни вреди или пропуснати ползи, вследствие неизпълнението на сключения РАМКОВ или ИНДИВИДУАЛЕН ДОГОВОР от Ваша страна, последният дължи пълно обезщетение на така причинените вреди, ведно с всички съдебни и извънсъдебни разноски във връзка с тяхното присъждане и събиране.
&nbsp;
1. 16.9. БЛОКЧЕЙН ТЕХ не носи отговорност за вреди, причинени на софтуера, хардуера или съоръженията и оборудването на КЛИЕНТ или посетител на CryptoDesk, или за загуба на данни, произтекли от материали или ресурси, заредени или използвани по какъвто и да било начин посредством CryptoDesk и предоставяните на него услуги.
&nbsp;
1. 16.10. БЛОКЧЕЙН ТЕХ не носи отговорност за характера на съдържанието и материалите, находящи се в Интернет страниците и ресурсите, станали достъпни чрез поставените в CryptoDesk и във Вашия АКАУНТ електронни препратки (линкове), рекламни банери и търговски съобщения от трети лица, както и за дейността на лицата и/или услугите, които те предоставят, които са представени чрез такива препратки (линкове). БЛОКЧЕЙН ТЕХ не носи отговорност за претърпени вреди и пропуснати ползи, произтекли от= използването, достъпа до или недостоверността на тези материали и съдържание.
&nbsp;
1. 16.11. Предвиденото в тези Общи условия не води до ограничаване на отговорността на БЛОКЧЕЙН ТЕХ за каквито и да е вреди, за които съгласно задължителни правни норми на приложимото законодателство не е допустимо такова ограничаване на отговорността.

>**17\.  ПРЕКРАТЯВАНЕ НА РАМКОВИЯ ДОГОВОР**

1. 17.1. Освен в изрично предвидени в настоящите Общи условия случаи, сключеният с БЛОКЧЕЙН ТЕХ РАМКОВ ДОГОВОР се прекратява при:
1. 17.1.1. преустановяване на дейността или прекратяване на БЛОКЧЕЙН ТЕХ;
1. 17.1.2. прекратяване поддържането на CryptoDesk;
1. 17.1.3. премахването на АКАУНТА Ви от CryptoDesk;
1. 17.1.4. взаимно съгласие на страните; или
1. 17.1.5. в други предвидени в закона случаи.
&nbsp;
1. 17.2. Вие имате право по всяко време по своя собствена преценка да спрете ползването на услугите и едностранно да прекратите сключения с БЛОКЧЕЙН ТЕХ РАМКОВ ДОГОВОР, като прекратите регистрацията си в CryptoDesk и съгласно т. 9.7 по-горе.
&nbsp;
1. 17.3. Сключен с БЛОКЧЕЙН ТЕХ ИНДИВИДУАЛЕН ДОГОВОР се прекратява:
1. 17.3.1. в предвидените в тези Общи условия случаи;
1. 17.3.2. по взаимно съгласие на страните; или
1. 17.3.3. в други предвидени в закона случаи.

>**V. ДРУГИ РАЗПОРЕДБИ**

>**18\.  ПРАВА НА ИНТЕЛЕКТУАЛНАТА СОБСТВЕНОСТ**

1. 18.1. Вие признавате и се съгласявате, че всички ПРАВА НА ИНТЕЛЕКТУАЛНА СОБСТВЕНОСТ върху CryptoDesk са собственост на БЛОКЧЕЙН ТЕХ и/или на трето лице (в зависимост от случая) и Вие нямате никакви права върху CryptoDesk, освен правото да го използвате в съответствие с изричните условия на тези Общи условия и сключения с БЛОКЧЕЙН ТЕХ РАМКОВ ДОГОВОР.
&nbsp;
1. 18.2. Вашето право на достъп като КЛИЕНТ или посетител на CryptoDesk не включва правото да се копира или възпроизвежда информация и да се използват обекти на интелектуална собственост, освен ако се касае за незначителна по обем информация, предназначена за лично ползване, при условие, че не се увреждат неоправдано законните интереси на авторите или други носители на ПРАВА НА ИНТЕЛЕКТУАЛНА СОБСТВЕНОСТ и в случай че копирането или възпроизвеждането се извършват с нетърговска цел. Независимо от горното Вие като КЛИЕНТ на БЛОКЧЕЙН ТЕХ, както и всеки друг посетител на CryptoDesk, нямате право да премахвате знаците за търговска марка и принадлежност на друго ПРАВО НА ИНТЕЛЕКТУАЛНА СОБСТВЕНОСТ от достъпните му ресурси, независимо дали носителят на съответните права е БЛОКЧЕЙН ТЕХ или трето лице.

>**19\.  ЛИЧНИ ДАННИ**

&nbsp; Във връзка с предоставяните чрез CryptoDesk услуги, БЛОКЧЕЙН ТЕХ обработва лични данни съгласно своята Политика за защита на личните данни, с която можете да се запознаете тук: https://cryptodesk.bg/privacy. Политиката е неразделна част
от тези Общи условия.

>**20\.  СЪОБЩЕНИЯ И ПИСМЕНА ФОРМА**

1. 20.1. Писмената форма се счита за осъществена с изпращането на електронна поща, натискането на виртуален бутон в уебсайта CryptoDesk или други подобни действия, доколкото изявлението е технически съхранено по начин, който позволява възпроизвеждането му.
&nbsp;
1. 20.2. Вие се съгласявате, че цялата и всякаква комуникация и документи между Вас и БЛОКЧЕЙН ТЕХ могат да бъдат обменяни чрез електронна поща или други електронни средства.
&nbsp;
1. 20.3. Всички известия до БЛОКЧЕЙН ТЕХ във връзка с настоящите Общи условия и/ или сключен с БЛОКЧЕЙН ТЕХ РАМКОВ И/ИЛИ ИНДИВИДУАЛЕН ДОГОВОР трябва да се изпращат през съответните електронни форми в CryptoDesk и/или на контактите, посочени на https://cryptodesk.bg.

>**21\.  ПРИЛОЖИМО ПРАВО И РАЗРЕШАВАНЕ НА СПОРОВЕ**

1. 21.1. Към настоящите Общи условия и сключените между Вас и БЛОКЧЕЙН ТЕХ РАМКОВ ДОГОВОР и към всеки ИНДИВИДУАЛЕН ДОГОВОР, както и за всички неуредени в тях въпроси, се прилагат разпоредбите на действащото в Република България законодателство.
&nbsp;
1. 21.2. Всички спорове, породени от настоящите Общи условия и сключения между Вас и БЛОКЧЕЙН ТЕХ РАМКОВ и/или ИНДИВИДУАЛЕН ДОГОВОР или отнасящи се до тях, ще бъдат разрешавани от компетентния съд в град София.
&nbsp;
1. 21.3. Страните се съгласяват при възникнал спор относно осъществяването на ОПЕРАЦИЯ като първа стъпка да извършат проверка в публичен block explorer (blockchain.com / etherscan.com или друг подобен), като съхранената в него информация ще има установително действие в отношенията между страните.
&nbsp;
1. 21.4. Информираме Ви, че орган за извънсъдебно/ алтернативно решаване на спорове (АРС), който е компетентен да разглежда спор относно предоставяните по силата на тези Общи условия услуги, е Общата Помирителна комисия към Комисията за защита на потребителите със седалище – гр. София, и район на действие – територията на град София, Софийска област, област Кюстендил и област Перник. Адресът на Общата Помирителна комисия е: гр. София 1000, ул. „Врабча“ № 1, ет. 3, 4 и 5, тел. 02/ 9330 517, www.kzp.bg и електронна поща adr.sofia@kzp.bg. Също така Ви информираме, че Европейската комисия поддържа платформа за извънсъдебно онлайн решаване на спорове, която е достъпна тук: www.ec.europa.eu/consumers/odr. На тази платформа можете да откриете списък на органите за алтернативно решаване на спорове, които могат да съдействат за АРС. БЛОКЧЕЙН ТЕХ не е задължено да участва в процедури за разрешаване на спорове пред органи за АРС, освен ако изрично не е предвидено такова негово задължение в приложимото законодателство или БЛОКЧЕЙН ТЕХ не е изразил изричното си съгласие за участие в такава процедура.

&nbsp; Настоящите Общи условия са приети с решение на управителя на „БЛОКЧЕЙН ТЕХ” ЕООД на 23.08.2022 г., в сила от 23.08.2022 г. и са публикувани на уебсайта на „БЛОКЧЕЙН ТЕХ“ ЕООД – CryptoDesk, на български език.
      `,
    }
  },
}
</script>

<style lang="scss" scoped>
</style>
